<template>
    <div class="chat__box__file__area show">
        <div class="title">
            <p>{{ $t('Files') }} <span class="attachment__subtitle">{{ $t('Showing newest first') }}</span></p>
        </div>

        <ul class="shared__files">
            <li class="shared__item" v-for="(attachment, index) in pageState.attachments" v-bind:key="index">
                <div class="content">
                    <p class="file__title"><i :class="attachment.media_icon"></i>{{ attachment.media_name }}</p>
                    <p class="more">
                        <span class="file__format">{{ attachment.media_type }}</span>
                        <span class="file__size">{{ attachment.media_size }}</span>
                    </p>
                </div>
                <div class="download">
                    <a :href="attachment.attachment" target="_blank"><i class="eicon e-download"></i></a>
                </div>
            </li>

            <li v-if="isAttachmentEmpty" class="text-danger">
                {{ $t('No attachments found.') }}
            </li>
        </ul>

    </div>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: "ConversationAttachment",
    computed: {
        ...mapState(['pageState']),

        isAttachmentEmpty() {
            return this.pageState.attachments?.length === 0;
        }
    }
}
</script>

<style scoped>
.message__wrapper .chat__box .chat__box__file__area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    border-left: 1px solid #f5f7fd;
    padding: 20px 0px 20px 20px;
    display: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #fff;
    border-radius: 10px;
}

.message__wrapper .chat__box .chat__box__file__area.show {
    display: block
}

.message__wrapper .chat__box .chat__box__file__area .title p {
    font-size: 17px;
    font-weight: 500;
    color: #1c2238;
    line-height: 1;
}

.message__wrapper .chat__box .chat__box__file__area .shared__files {
    margin: 0;
    list-style: none;
    overflow-y: auto;
    padding: 0 20px 0 0;
    height: calc(100vh - 396px);
    min-height: 461px;
}

@media all and (max-width: 1600px) {
    .message__wrapper .chat__box .chat__box__file__area .shared__files {
        height: calc(100vh - 384px);
    }
}

@media all and (max-width: 1200px) {
    .message__wrapper .chat__box .chat__box__file__area .shared__files {
        height: calc(100vh - 325px);
    }
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li {
    border: 1px solid rgba(89, 125, 252, 0.3);
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li.text-danger {
    justify-content: center;
    font-size: 12px;
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li:not(:last-child) {
    margin-bottom: 10px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content .file__title {
    font-size: 14px;
    font-weight: 500;
    color: #1c2238;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content .file__title i {
    color: #597dfc;
    margin-right: 5px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content .more {
    font-size: 12px;
    font-weight: 400;
    color: #7d8091
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content .more .file__format {
    position: relative;
    padding-right: 10px;
    margin-right: 5px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .content .more .file__format:before {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background: #597dfc;
    content: '';
    right: 0
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .download {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .download a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border: 1px solid rgba(89, 125, 252, 0.3);
    border-radius: 5px;
    text-align: center;
    color: #597dfc;
    display: inline-block
}

.message__wrapper .chat__box .chat__box__file__area .shared__files li .download a:hover {
    background: #597dfc;
    color: #fff;
    border-color: #597dfc
}

@media all and (max-width: 1199px) {
    .message__wrapper .chat__box .chat__box__file__area {
        position: absolute;
        top: 0;
        right: -300px;
        bottom: 0;
        z-index: 9;
        display: block
    }

    .message__wrapper .chat__box .chat__box__file__area.show {
        right: 0
    }
}

.message__wrapper .chat__box .chat__box__file__area .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
    padding-right: 20px;
}

.message__wrapper .chat__box .chat__box__file__area .title .button {
    font-size: 12px;
    font-weight: 500;
    background: rgba(255, 95, 116, 0.1);
    color: #ff5f74;
    padding: 5px 13px;
    border-radius: 5px;
    min-height: auto;
}

@media all and (max-width: 991px) {
    .message__wrapper .chat__box .chat__box__file__area .title p {
        font-size: 13px;
    }
}

.message__wrapper .chat__box .chat__box__file__area .shared__files::-webkit-scrollbar {
    width: 7px;
}

.message__wrapper .chat__box .chat__box__file__area .shared__files::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(125, 128, 145, 0.3);
    background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
}

.message__wrapper .chat__box .chat__box__file__area .shared__files::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #597dfc;
}

.message__wrapper .chat__box .chat__box__file__area .title .button {
    padding: 5px;
    cursor: pointer;
}

.message__wrapper .chat__box .chat__box__file__area .title .button img {
    height: 20px;
    width: 20px;
    padding: 0 !important;
    transform: rotate(180deg);
}

.attachment__subtitle {
    font-size: 12px !important;
    display: inline-block;
    color: #7d8091 !important;
    font-weight: 400 !important;
    width: 100% !important;
}

</style>
